import '../css/load.scss';

(function ($) {
	const $win = $(window);
	const $doc = $(document);
	const stripe = Stripe(appData.ajaxUrl.stripeData);

	initHeaderMenuToggler();
	initLanguageSwitcher();
	initTimeCounter();
	initOffersSelector();
	initSaleSubmit();
	handleStripePayment();

	function addOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').addClass('no-scroll');
		$overlay.addClass('active');
	}

	function removeOverlay(ovElement) {
		const $overlay = $(`${ovElement}`);
		if (!$overlay.length) {
			return;
		}

		$('body').removeClass('no-scroll');
		$overlay.removeClass('active');
	}

	function scrollToSection() {
		$('a.btn-scroller').on('click', function (e) {
			let sectionId = $(this).attr('href');
			if (!sectionId.startsWith('#')) {
				return;
			}

			e.preventDefault();

			$('html, body').animate(
				{
					scrollTop: $(sectionId).offset().top - 40
				},
				1000
			);
		});
	}

	function initHeaderMenuToggler() {
		if (!$('.header__menu-toggle').length) {
			return;
		}

		const $header = $('.header__container');

		$('.header .header__menu-toggle').on('click', function (e) {
			$(this).closest('.header__container').toggleClass('menu-open');
			addOverlay('.app-loader-overlay');
			e.preventDefault();
		});

		$('.header__menu-close svg').on('click', function (e) {
			$('.header__container').toggleClass('menu-open');
			e.preventDefault();
			removeOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($header.hasClass('menu-open')) {
				if (!$header.is(e.target) && $header.has(e.target).length === 0) {
					$header.removeClass('menu-open');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	function initLanguageSwitcher() {
		if (!$('#lang-switcher-btn').length) {
			return;
		}

		const $langSwitcher = $('#lang-switcher');

		$('#lang-switcher-btn').on('click', function (e) {
			e.preventDefault();

			$langSwitcher.toggleClass('active');
			$('.app-loader-overlay').css('z-index', '999997');
			addOverlay('.app-loader-overlay');
		});

		$doc.mouseup(function (e) {
			if ($langSwitcher.hasClass('active')) {
				if (!$langSwitcher.is(e.target) && $langSwitcher.has(e.target).length === 0) {
					$langSwitcher.removeClass('active');
					$('.app-loader-overlay').css('z-index', '101');
					removeOverlay('.app-loader-overlay');
				}
			}
		});
	}

	function scrollToSection() {
		$('a.btn-scroller').on('click', function (e) {
			let sectionId = $(this).attr('href');
			if (!sectionId.startsWith('#')) {
				return;
			}

			e.preventDefault();

			$('html, body').animate(
				{
					scrollTop: $(sectionId).offset().top - 40
				},
				1000
			);
		});
	}

	function initTimeCounter() {
		if (!document.getElementById('time-counter')) {
			return;
		}

		const elementContainer = document.getElementById('time-counter');
		const timeInSec = elementContainer.dataset.counter;
		const countDownDate = new Date().getTime() + timeInSec * 1000;

		var x = setInterval(function () {
			const now = new Date().getTime();
			const distance = countDownDate - now;

			const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			let seconds = Math.floor((distance % (1000 * 60)) / 1000);

			if (seconds < 10) {
				seconds = '0' + seconds;
			}

			if (hours > 0) {
				elementContainer.innerHTML = hours + ' : ' + minutes + ' : ' + seconds;
			} else {
				elementContainer.innerHTML = minutes + ' : ' + seconds;
			}

			if (distance <= 0) {
				clearInterval(x);
				document.getElementById('time-counter').innerHTML = '';
			}
		}, 1000);
	}

	function initOffersSelector() {
		if (!$('input[name="offer"]').length) {
			return;
		}

		$('input[name="offer"]').on('change', function () {
			$('input[name="input_1"]').val(this.value);
		});

		$doc.ready(function () {
			$('input[name="input_1"]').val($('input[name="offer"]:checked').val());
		});
	}

	// testing
	function initSaleSubmit() {
		$('.section--purchase-sale form .gfield_radio label').on('mouseup', function () {
			let selection = $(this).attr('for');
			let $selectionInput = $('#' + selection);
			$selectionInput.prop('checked', true);
			$(this).closest('form').submit();
		});
	}

	function handleStripePayment() {
		if (document.getElementById('payment-element') === null) {
			return;
		}

		const appearance = {
			theme: 'stripe'
		};
		const options = {
			mode: 'setup',
			amount: 0,
			currency: 'eur',
			setupFutureUsage: 'off_session',
			paymentMethodTypes: ['card'],
			paymentMethodCreation: 'manual',

			appearance: appearance,
			layout: 'tabs',
			defaultCollapsed: false,
			locale: 'fr'
		};
		const elements = stripe.elements(options);
		const paymentElement = elements.create('payment');

		$doc.on('gform_post_render', function (event, formId) {
			const formSelector = '.form-purchase form';
			const form = document.querySelector(formSelector);
			const currentFormId = form.getAttribute('id');
			const currentFormIdArray = currentFormId.split('_');
			const currentFormIdNumber = +currentFormIdArray[1];

			if (formId === currentFormIdNumber) {
				paymentElement.mount('#payment-element');
			}
		});

		document.addEventListener('submit', async (event) => {
			const formSelector = '.form-purchase form';
			const form = document.querySelector(formSelector);
			const currentFormId = form.getAttribute('id');
			const currentFormIdArray = currentFormId.split('_');
			const currentFormIdNumber = +currentFormIdArray[1];

			if (!event.target.matches('#' + currentFormId)) {
				return;
			}

			event.preventDefault();
			const formIsValid = validatePaymentForm(formSelector);

			if (formIsValid === false) {
				form.submit();
				$('html, body').animate(
					{
						scrollTop: $('#gform_1').offset().top - 40
					},
					1000
				);
			}

			const firstName = document.querySelector("[name='input_11']").value;
			const lastName = document.querySelector("[name='input_12']").value;
			const email = document.querySelector("[name='input_13']").value;
			const line1 = document.querySelector("[name='input_17']").value;
			const city = document.querySelector("[name='input_19']").value;
			const postalCode = document.querySelector("[name='input_18']").value;
			const country = document.querySelector("[name='input_20']").value;
			const amount = 100;

			const { error: submitError } = await elements.submit();
			if (submitError) {
				console.error('Error submitting elements:', submitError.message);
				document.getElementById('payment-result').textContent = submitError.message;
				window["gf_submitting_" + currentFormIdNumber] = false;
				$('.gform_ajax_spinner').remove();
				return false;
			}

			const stripeClientSecret = await createSetupIntent(
				firstName,
				lastName,
				email,
				line1,
				city,
				postalCode,
				country,
				0
			);

			const { setupIntent, error } = await stripe.confirmSetup({
				elements,
				clientSecret: stripeClientSecret,
				confirmParams: {
					return_url: window.location.origin + '/thank-you-page'
				},
				redirect: 'if_required'
			});

			if (error) {
				console.log(error);
				document.getElementById('payment-result').textContent = error.message;
				window["gf_submitting_" + currentFormIdNumber] = false;
				$('.gform_ajax_spinner').remove();
				return false;
			} else {
				document.getElementById('payment-result').textContent = 'Payment successful!';
				const stData = await fetchSetupDetails(setupIntent.id);

				if ($('input[name="input_7"]').length) {
					$('input[name="input_7"]').val(stData);
				}

				form.submit();
			}
		});
	}

	async function createSetupIntent(
		firstName,
		lastName,
		email,
		line1,
		city,
		postalCode,
		country,
		amount
	) {
		const response = await fetch(
			appData.ajaxUrl[0] + '?action=app_stripe_create_setup_intent',
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					amount: amount,
					shipping: {
						name: `${firstName} ${lastName}`,
						address: {
							line1: line1,
							city: city,
							postal_code: postalCode,
							country: country
						}
					},
					email: email
				})
			}
		);
		const data = await response.json();

		return data.data.clientSecret;
	}

	async function fetchSetupDetails(setupIntentId) {
		const response = await fetch(
			appData.ajaxUrl[0] +
				'?action=app_stripe_fetch_setup_details&ihaSetupIntentId=' +
				setupIntentId
		);
		const setupDetailsRes = await response.json();

		const setupDetails = setupDetailsRes.data.setupIntent;

		// Map the setup intent data to your custom structure
		const structuredData = {
			orderData: {
				order_type: 'initial', // Hardcoded value
				store: 'explora_media_fr', // Hardcoded value
				payment: {
					method: 'stripe_payments', // Hardcoded value
					additional_information: {
						customer_stripe_id: setupDetails.customer,
						tr_id: setupDetails.id,
						token: setupDetails.payment_method,
						is_captured: false
					}
				}
			}
		};

		// Display the structured order data
		const resultData = JSON.stringify(structuredData, null, 2);
		// document.getElementById('payment-details').textContent = resultData;

		return resultData;
	}

	function validatePaymentForm(formSelector) {
		let isValid = true;
		const formFields = {
			name1: $(formSelector).find('[name="input_11"]').val() ?? '',
			name2: $(formSelector).find('[name="input_12"]').val() ?? '',
			email1: $(formSelector).find('[name="input_13"]').val() ?? '',
			email2: $(formSelector).find('[name="input_13_2"]').val() ?? '',
			phone: $(formSelector).find('[name="input_15"]').val() ?? '',
			dob: $(formSelector).find('[name="input_14"]').val() ?? '',
			street: $(formSelector).find('[name="input_17"]').val() ?? '',
			postcode: $(formSelector).find('[name="input_18"]').val() ?? '',
			city: $(formSelector).find('[name="input_19"]').val() ?? '',
			country: $(formSelector).find('[name="input_20"]').val() ?? '',
			tac1: $(formSelector).find('[name="input_32.1"]').val() ?? '',
			tac2: $(formSelector).find('[name="input_36.1"]').val() ?? ''
		};

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const emailField1 = formFields.email1.trim();
		const emailField2 = formFields.email2.trim();
		if (emailField1 !== emailField2) {
			return false;
		}
		if (emailField1 && !emailRegex.test(emailField1)) {
			return false;
		}
		if (emailField2 && !emailRegex.test(emailField2)) {
			return false;
		}

		for (const [key, value] of Object.entries(formFields)) {
			if (!value || value.trim() === '') {
				isValid = false;
				break;
			}
		}

		return isValid;
	}
})(jQuery);
